import { base } from '@theme-ui/presets'
import { toTheme } from '@theme-ui/typography'
import merge from 'deepmerge'

const typography = toTheme({
  blockMarginBottom: 8,
  bodyFontFamily: ['Arial', 'sans-serf'],
  headerFontFamily: ['Playfair', 'serif']
})

const defaultButton = {
  cursor: 'pointer',
  borderRadius: '8px',
  border: 0,
  height: '51px',

  fontFamily: 'Inter, sans-serif',
  fontSize: '14px',
  lineHeight: '17px',
  letterSpacing: '-0.16px',
  padding: '16px',
  color: '#515960',
}

const theme = {
  sizes: {
    container: 1340,
  },
  layout: {
    header: {
      flexDirection: 'column',
      alignItems: 'center',
    },
    main: {
      flexDirection: ['column', 'row', 'row'],
    },
  },
  text: {
    video: {
      large: {
        marginBottom: 5,
        fontFamily: 'Playfair Display, serif',
        fontSize: '40px',
        lineHeight: '53.2px',
        color: '#000000',
      },
    },
    banner: {
      width: '100%',
      p: 4,
      fontFamily: 'Playfair Display, serif',
      fontSize: '20px',
      lineHeight: '29px',
      color: '#4D4D4D',
      textAlign: 'center',
    },
    heading: {
      color: '#ffc801',
      mb: 2,
    },
    paragraph: {
      fontSize: 1,
      mb: 3,
    },
    theme: {
      height: '46px',
      fontSize: '18px',
      lineHeight: '22px',
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      justifyContent: 'center',
      letterSpacing: '-0.16px',
    },
  },
  links: {
    button: {
      cursor: 'pointer',
      bg: '#f4436c',
      color: 'white',
      width: '100%',
      borderRadius: 0,
      display: 'block',
      padding: 2,
      textAlign: 'center'
    },
    demo: {
      cursor: 'pointer',
      color: '#f4436c'
    },
  },
  boxes: {
    right: {
      height: '100vh',
      overflowY: 'auto',
      flex: [1, '0 0 50%', '0 0 50%'],
      flexDirection: 'column',
    },
    success:{
      bg: '#121838',
      color: 'white',
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh'
    },
    successFooter: {
      bg: '#1c283d',
      p: 5
    },
    form: {
      flex: 1,
      height: '100%',
      flexDirection: 'column',
      mb: '130px',
    },
    formGroup: {
      px: 5,
      py: 3,
    },
    inputGroup: {
    },
    video: {
      textAlign: 'center',
      height: '100vh',
      bg: 'muted',
      flex: [1, '0 0 50%', '0 0 50%'],
      p: 5,
      alignItems: 'center',
      '.video-react-big-play-button': {
        display: 'none',
      },
    },
    placeholder: {
      width: '100%',
      px: 3,
    },
    theme: {
      cursor: 'pointer',
      borderRadius: '8px',
      overflow: 'hidden',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: '#E2E2E2'
    },
    formFooter: {
      bg: '#31364C',
      px: 5,
      py: 3,
      position: ['relative', 'fixed'],
      bottom: 0,
      right: 0,
      width: ['100%', '50%'],
    },
  },
  forms: {
    label: {
      fontFamily: 'Open Sans, sans-serif',
      fontSize: '20px',
      lineHeight: '27px',
      marginBottom: '4px',
    },
    input: {
      bg: '#F2F4F5',
      ...defaultButton,
    },
    invalid: {
      bg: '#F2F4F5',
      ...defaultButton,
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: '#D0573E',
    },
  },
  buttons: {
    selected: {
      ...defaultButton,
      bg: '#7F8D99',
      color: '#FFFFFF',
    },
    submit: {
      ...defaultButton,
      bg: '#D0573E',
      color: '#FFFFFF',
      '&[disabled]': {
        cursor: 'default',
        opacity: 0.6,
      },
    },
    muted: {
      ...defaultButton,
      bg: '#F2F4F5',
      color: '#515960',
    },
    outline: {
      ...defaultButton,
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: '#7F8D99',
      bg: '#ffffff',
      color: '#7F8D99',
    },
  },
}

export default merge.all([base, typography, theme])
